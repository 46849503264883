import React, { Suspense, useState, lazy } from "react";
import { File, Input, Button, Spinner, FormItem, Div } from "@vkontakte/vkui";
import { isValidLandingImageUrl } from "../../../includes/Helpers/HelpersPages";
import { isValidUrl } from "../../../includes/Helpers/Helpers";
import FileService from "../../../includes/Services/FileService";
import ImagesHelper from "../../../includes/Helpers/ImagesHelper";
import container from "../../../container";
import {
    IMAGE_SIZE_ERROR,
    IMAGE_UPLOAD_ERROR,
} from "../../../includes/ErrorMessages";
import { Icon24CameraOutline } from "@vkontakte/icons";
import * as Sentry from "@sentry/react";

const ImageCropper = lazy(() => import("../../Controls/ImageCropper"));

declare type ImageUploaderProps = {
    id: string;
    callback: Function;
    size: number[]; // [1920, 1220]
    params: any;
    hasDescription: boolean;
    hasAddressUrl: boolean;
    description: string;
    imageUrl: string;
    address: string;
    withCrop: boolean;
    mainPopout: any;
};

const logger = container.get("logger");

const ImageUploader = (props: ImageUploaderProps) => {
    const {
        callback,
        size = [1920, 1220],
        hasDescription = false,
        hasAddressUrl = false,
        description = "",
        imageUrl = "",
        address = "",
        withCrop = true,
    } = props;

    const [isPending, setIsPending] = useState(false);
    const [fileError, setFileError] = useState("");
    const [urlError, setUrlError] = useState("");
    const [url, setUrl] = useState(imageUrl);
    const [addressUrlError, setAddressUrlError] = useState("");
    const [addressUrl, setAddressUrl] = useState(address);
    const [desc, setDesc] = useState(description);
    const [cropperImageDataUrl, setCropperImageDataUrl] = useState(null);

    const handleFileChange = async (e) => {
        let file = e.target.files[0];

        if (!file) {
            return false;
        }

        let imageDataUrl = await ImagesHelper.readImageFile(file);

        if (withCrop) {
            setCropperImageDataUrl(imageDataUrl);
        } else {
            const sizes = await ImagesHelper.getImageFileDimensions(
                imageDataUrl
            );
            uploadImage({
                file: file,
                size_x: sizes.size_x,
                size_y: sizes.size_y,
            });
        }
    };

    const resetImage = () => {
        setCropperImageDataUrl(null);
        const fileInput = document.querySelector(
            "#modal-landing-img .vkuiFile__input"
        ) as any;
        if (fileInput) {
            fileInput.value = null;
        }
    };

    const onCropperSubmit = async (data) => {
        resetImage();
        uploadImage(data);
    };

    const uploadImage = async (data) => {
        try {
            setIsPending(true);

            if (data.file.size > 7000000) {
                setFileError(IMAGE_SIZE_ERROR);
                setIsPending(false);
                return;
            }

            let resp = await FileService.uploadImage({
                image: data.file,
                size: `${data.size_x}x${data.size_y}`,
            });

            if (resp.result === "success") {
                setFileError("");

                setUrl(resp.data.filename);
                setIsPending(false);
            } else {
                logger.error(
                    {
                        code: 9103,
                        message: resp.message,
                    },
                    "images_error",
                    "ImageUploader.tsx"
                );
                console.log(resp.message);

                setFileError(IMAGE_UPLOAD_ERROR);
                setIsPending(false);
            }
        } catch (error) {
            logger.error(
                {
                    code: 9104,
                    message: error.message,
                },
                "images_error",
                "ImageUploader.tsx"
            );
            Sentry.captureException(error);
            console.log(error);

            setFileError(IMAGE_UPLOAD_ERROR);
            setIsPending(false);
        }
    };

    const handleInputChange = (e) => {
        let value = e.currentTarget.value;
        setUrl(value);
        setUrlError("");
    };

    const handleAddressUrlChange = (e) => {
        let value = e.currentTarget.value;
        setAddressUrl(value);
        setAddressUrlError("");
    };

    const handleDescChange = (e) => {
        let value = e.currentTarget.value;
        setDesc(value);
    };

    const handleSubmit = () => {
        if (!url) {
            setUrlError("Необходимо заполнить");
            return false;
        }

        const isValid = isValidLandingImageUrl(url);
        const isValidAddressUrl = isValidUrl(addressUrl);

        if (!isValid) {
            setUrlError("Неверный формат ссылки");
            return false;
        }

        if (addressUrl) {
            if (!isValidAddressUrl) {
                setAddressUrlError("Разрешены ссылки внутри vk.com");
                return false;
            }
        }

        callback({
            url: url,
            address_url: addressUrl,
            desc: desc,
        });
    };

    return (
        <div
            className={`BannerSelect ${
                isPending ? "BannerSelect__isPending" : ""
            }`}
        >
            {withCrop && (
                <Suspense fallback={<Spinner size="small" />}>
                    <ImageCropper
                        onClose={resetImage}
                        onSubmit={onCropperSubmit}
                        imageInputData={cropperImageDataUrl}
                    />
                </Suspense>
            )}
            {isPending && (
                <div className="BannerSelect__spinner">
                    <Spinner size="regular" color="#000" />
                </div>
            )}

            <FormItem
                style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
                status={fileError ? "error" : "default"}
                bottom={
                    fileError
                        ? fileError
                        : `Рекомендуемый размер - ${size.join("x")}`
                }
            >
                <File
                    className="BannerSelect__file"
                    size="l"
                    mode="outline"
                    onChange={handleFileChange}
                    before={<Icon24CameraOutline />}
                    align="center"
                    stretched
                >
                    <span>Откройте файл</span>
                </File>
            </FormItem>

            <FormItem
                top="Или вставьте ссылку на изображение"
                style={{ paddingLeft: 0, paddingRight: 0 }}
                status={urlError ? "error" : "default"}
                bottom={urlError}
            >
                <Input
                    value={url}
                    onChange={handleInputChange}
                    placeholder="Введите ссылку"
                />
            </FormItem>

            {hasAddressUrl && (
                <FormItem
                    top="Ссылка для баннера"
                    status={addressUrlError ? "error" : "default"}
                    bottom={addressUrlError ? addressUrlError : ""}
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                    <Input
                        value={addressUrl}
                        onChange={handleAddressUrlChange}
                        placeholder="Введите ссылку"
                    />
                </FormItem>
            )}

            {hasDescription && (
                <FormItem
                    top="Задайте описание"
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                    <Input
                        value={desc}
                        onChange={handleDescChange}
                        placeholder="Введите описание"
                    />
                </FormItem>
            )}

            <Div style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
                <Button stretched size="l" onClick={handleSubmit}>
                    Сохранить
                </Button>
            </Div>
        </div>
    );
};

export default ImageUploader;
