import {
    WidgetDescription,
    AppModal,
    PageStatisticSummary,
} from "./Structures";

export const WIDGET_STATUS_ACTIVE: number = 1;
export const WIDGET_STATUS_INACTIVE: number = 0;
export const WIDGET_MAX_AUDIENCE_COMMUNITIES: number = 20;
export const WIDGET_MAX_AUDIENCE_COMMUNITIES_INFO: string = `Максимум 20 сообществ на оба фильтра "Состоит в сообществе" и "Не состоит в сообществе". Лимит действует на все виджеты данного типа.`;
export const PUBLISH_TIMEOUT: number = 10000; // Для публикации виджета не чаще, чем 1 раз в 10 секунд
export const AUDIENCE_ANY: string = "any";

export const GROUP_NOT_FOUND_MESSAGE = "Group not found";
export const URL_PLACEHOLDER = "Внутренняя ссылка vk.com, vk.me, vk.cc/{id} или vk.link";
export const EMPTY_FUNC = () => {};

export const MAX_COMMUNITY_WIDGET_COUNT = 100;

export const MAX_FILE_UPLOAD_SIZE = 7; // Mb

export const MATCH_ALL_LINE_BREAKS_REGEXP = /(<br>|<br\s*\/>|\r\n|\r|\n)/gi;
export const TEXT_LINE_BREAKS_REGEXP = /(\r\n|\r|\n)/gi;
export const MATCH_HTML_LINE_BREAKS_REGEXP = /(<br>|<br\s*\/>)/gi;

export const FIRST_NAME_VAR_REGEXP = /({first_name}|{first_name(_?).{3}})/gi;
export const LAST_NAME_VAR_REGEXP = /({last_name}|{last_name(_?).{3}})/gi;
export const USER_ID_REG_EXP = /{user_id}/gi;
export const USER_FRIEND_COUNT_REG_EXP = /{user_friends_count}/gi;
export const GENDER_VAR_REGEXP = /(\[gender]([^|\[]*)\|?([^\[]*)\[\/gender])/gi;

/**
 * Работа со строками
 */
export const EMOJI_REGEX =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\ud83d|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]|\ud83e)/g;
export const JOINERS_ENTITIES = /([\u200D])/gi; // zwj
export const SPACES_ENTITIES = /([\u2003]|[\u2009])/gi; // emsp, thinsp
export const SPECIAL_CHARS_REGEX = /(["&<>:#$^₽!])/g;
export const EMOJI_COUNT = 11;
export const SPECIAL_CHAR_COUNT = 9;
export const ZERO_WIDTH_JOINER = "200d";
export const MAX_STRING_LENGTH = 100;
export const MAX_MESSAGE_DESCRIPTION_LENGTH = 200;
export const MAX_BUTTON_STRING_LENGTH = 50;
export const MAX_CONTINUED_STRING_LENGTH = 96;

/**
 * Общие данные
 */
export const LOCATION_WIDGET_HOME = {
    list: "widgets",
    view: "main",
    panel: "add",
};
export const MODAL_NULL: AppModal = { id: null, args: {} };
export const BUTTON_WIDTH = 100;
export const DEFAULT_FOOTER_MSG = "Тут будет список ваших виджетов";
export const SEARCH_FOOTER_MSG = "По вашему запросу ничего не найдено";

export const FIRST_NAME_CASES = [
    { name: "Именительный", value: "first_name" },
    { name: "Родительный", value: "first_name_gen" },
    { name: "Дательный", value: "first_name_dat" },
    { name: "Винительный", value: "first_name_acc" },
    { name: "Творительный", value: "first_name_ins" },
    { name: "Предложный", value: "first_name_abl" },
];
export const LAST_NAME_CASES = [
    { name: "Именительный", value: "last_name" },
    { name: "Родительный", value: "last_name_gen" },
    { name: "Дательный", value: "last_name_dat" },
    { name: "Винительный", value: "last_name_acc" },
    { name: "Творительный", value: "last_name_ins" },
    { name: "Предложный", value: "last_name_abl" },
];

export const OTHER_VARIABLES = [
    { name: "Пол", value: "[gender]Для мужчины|Для женщины[/gender]" },
    { name: "ID пользователя", value: "user_id" },
    { name: "Количество друзей посетителя", value: "user_friends_count" },
];

export const PROMO_PAGES_VARIABLES = [
    // {
    //     name: 'Имя',
    //     value: FIRST_NAME_CASES.map(v => {
    //         return {...v, value: `{${v.value}}`}
    //     })
    // },
    // {
    //     name: 'Фамилия',
    //     value: LAST_NAME_CASES.map(v => {
    //         return {...v, value: `{${v.value}}`}
    //     })
    // },
    {
        name: "Общие",
        value: [
            {
                name: "Имя",
                value: "%username%",
            },
            {
                name: "Полное имя",
                value: "%fullname%",
            },
            {
                name: "Город",
                value: "[city]%city%|город не выбран[/city]",
            },
            {
                name: "Страна",
                value: "[country]%country%|страна не выбрана[/country]",
            },
            {
                name: "Семейное положения",
                value: "[relation]%relation%|семейное положение не выбрано[/relation]",
            },
            {
                name: "Мужчинам|Женщинам",
                value: "[gender]Для мужчины|Для женщины[/gender]",
            },
            {
                name: "Дата",
                value: "[date]%e %month|+1 day[/date]",
            },
        ],
    },
];

export const VARIABLES = [
    {
        name: "Имя",
        value: FIRST_NAME_CASES,
    },
    {
        name: "Фамилия",
        value: LAST_NAME_CASES,
    },
    {
        name: "Другие",
        value: OTHER_VARIABLES,
    },
];

export const APP_SETTINGS_VARIABLES = [
    {
        name: "Имя",
        value: "%username%",
    },
    {
        name: "Полное имя",
        value: "%fullname%",
    },
    {
        name: "Город",
        value: "[city]%city%|город не выбран[/city]",
    },
    {
        name: "Страна",
        value: "[country]%country%|страна не выбрана[/country]",
    },
    {
        name: "Семейное положения",
        value: "[relation]%relation%|семейное положение не выбрано[/relation]",
    },
    {
        name: "Мужчинам|Женщинам",
        value: "[gender]Для мужчины|Для женщины[/gender]",
    },
];

export const widgets: WidgetDescription[] = [
    {
        img: "carousel.svg",
        title: "Плитка с прямоугольными изображениями",
        description:
            "Поможет привлечь внимание к акциям или товарам и получить больше продаж",
        type: "tiles",
        type_api: "carousel",
        deprecated: false,
    },
    {
        img: "recommended.svg",
        title: "Плитка с квадратными изображениями",
        description: "Второй товарный блок для более активной продажи",
        type: "tiles",
        type_api: "promo",
        deprecated: false,
    },
    {
        img: "banners.svg",
        title: "Обложки с акцией",
        description: "До 3 горизонтальных баннеров с кнопками",
        type: "cover_list",
        type_api: "banners",
        deprecated: false,
    },
    {
        img: "message.svg",
        title: "Важное сообщение",
        description: "Опубликуйте важное сообщение с крупным текстом",
        type: "text",
        type_api: "message",
        deprecated: false,
    },
    {
        img: "feedbacks.svg",
        title: "Список",
        description: "Список с произвольными полями и кнопками для действий",
        type: "list",
        type_api: "list",
        deprecated: false,
    },
    {
        img: "feedbacks.svg",
        title: "Компактный список",
        description:
            'Выводит список элементов в компактном виде. Аналогичен виджету "Список", за исключением того, что кнопка располагается справа',
        type: "compact_list",
        type_api: "compact_list",
        deprecated: false,
    },
    {
        img: "personal.svg",
        title: "Персональное предложение",
        description: "Виджет с аватаркой посетителя",
        type: "list",
        type_api: "personal",
        deprecated: true,
    },
    {
        img: "feedbacks.svg",
        title: "Отзывы",
        description: "Опубликуйте лучшие отзывы от ваших клиентов",
        type: "list",
        type_api: "feedbacks",
        deprecated: false,
    },
    {
        img: "clients.svg",
        title: "Наши клиенты",
        description: "Опубликуйте список ваших клиентов в формате виджета",
        type: "table",
        type_api: "clients",
        deprecated: false,
    },
    {
        img: "sale-points.svg",
        title: "Точки продажи",
        description: "Опубликуйте список ваших точек продаж",
        type: "list",
        type_api: "pointSale",
        deprecated: true,
    },
    {
        img: "events.svg",
        title: "Мероприятия",
        description:
            "Составляйте индивидуальные подборки для пользователей по их интересам",
        type: "list",
        type_api: "events",
        deprecated: true,
    },
    {
        img: "community-menu.svg",
        title: "Меню сообщества",
        description: "Опубликуйте меню сообщества",
        type: "list",
        type_api: "community",
        deprecated: true,
    },
    {
        img: "subscriptions.svg",
        title: "Подписки на рассылки",
        description: "Подписки на рассылки",
        type: "list",
        type_api: "subscription",
        deprecated: true,
    },
];

export const platforms = [
    { id: 1, title: "Мобильная версия" },
    { id: 2, title: "iPhone" },
    { id: 3, title: "iPad" },
    { id: 4, title: "Android" },
    { id: 5, title: "Полная версия сайта" },
];

export const relations = [
    { id: 1, title: "Не женат/не замужем" },
    { id: 2, title: "Есть друг/есть подруга" },
    { id: 3, title: "Помолвлен/помолвлена" },
    { id: 4, title: "Женат/замужем" },
    { id: 5, title: "Всё сложно" },
    { id: 6, title: "В активном поиске" },
    { id: 7, title: "Влюблён/влюблена" },
    { id: 8, title: "В гражданском браке" },
    // {id: 0, title: 'Не указано'}
];

export const bDayList = [
    { title: "Сегодня", value: "today" },
    { title: "Завтра", value: "tomorrow" },
    { title: "В течение недели", value: "week" },
];

export const userCommunityRoles = [
    { label: "Любая", value: AUDIENCE_ANY },
    { label: "Участник сообщества", value: "member" },
    { label: "Не состоит в сообществе", value: "none" },
];

export const timeRanges = [
    { label: "До", value: "before" },
    { label: "После", value: "after" },
];

export const sexList: any = [
    { label: "Женский", value: 1 },
    { label: "Мужской", value: 2 },
    { label: "Не указан", value: 0 },
];

export const pagesButtonsActionTypes = [
    { title: "Ссылка", type: "url" },
    { title: "Подписаться на рассылку", type: "subscribe" },
    { title: "Добавить в бота", type: "bot" },
    { title: "Заявка с номером телефона", type: "lead" },
    { title: "Вступление в сообщество", type: "join_community" },
];

export const PAY_PERIOD_DAY = 1;
export const PAY_PERIOD_MONTH = 2;

export const DEFAULT_PAGE_SUMMARY_STATISTIC_DATA = {
    hits: 0,
    unique_user_count: 0,
    goals: 0,
    lead: 0,
    subscription: 0,
    join_community: 0,
    url: 0,
    bot_add: 0,
} as PageStatisticSummary;

/**
 * VK api error codes
 */

/**
 * Токен не действителен или отозван. Возможно после переустановки приложения
 */
export const TOKEN_NOT_VALID_ERROR = 27;

/**
 * Авторизация пользователя не удалась, возможно после переустановке приложения
 */
export const USER_AUTH_ERROR = 5;

/**
 * Код ошибки Flud control на стороне вк при публикации виджета
 */
export const VK_API_PUBLISH_TIMEOUT_ERROR = 9;

/**
 * Один из необходимых параметров был не передан или неверен.
 * Возможно id загруженных изображений после смены токена
 */
export const INVALID_PARAMS_ERROR = 100;

/**
 * Токен пользователя устарел
 */
export const USER_ACCES_TOKEN_HAS_EXPIRED = 1;

/**
 * В сообществе нужно переустановить приложение
 */
export const WIDGET_ACCESS_ERROR = 15;

/**
 * Пользователь отменил действие
 */
export const USER_DENIED_ERROR = 4;

/**
 * Неподдериваемая платформа для публикации виджета
 */
export const UNSUPORTED_PUBLISH_PLATFORM_ERROR_MESSAGE = 6;

export const DAYS = [
    { id: 1, name: "Пн" },
    { id: 2, name: "Вт" },
    { id: 3, name: "Ср" },
    { id: 4, name: "Чт" },
    { id: 5, name: "Пт" },
    { id: 6, name: "Сб" },
    { id: 0, name: "Вс" },
];

/**
 * Значение заливки оверлея в блоках: Обложка, таймеры
 */
export const DEFAULT_OVERLAY_OPACITY_VALUE = 40;

/**
 * Ссылка на диалог с сообществом Senler
 */
export const SENLER_DIALOG_URL = 'https://vk.com/im?sel=-139157852';
