import React, { useState, useContext } from "react";
import {
    File,
    Avatar,
    Separator,
    Input,
    FormLayout,
    Radio,
    Button,
    Spinner,
    platform,
    Platform,
    FormItem,
    Div,
} from "@vkontakte/vkui";
import ApiVk from "./../../../includes/ApiVk";
import WidgetService from "../../../includes/Services/WidgetService";
import { Context } from "../../../context";
import { MAX_FILE_UPLOAD_SIZE } from "../../../includes/Constants";
import { UNKNOWN_ERROR } from "../../../includes/ErrorMessages";
import Config from "../../../includes/Config";
import { getIconIdType, parseIconId } from "../../../includes/Helpers/Helpers";
import { Icon24Document, Icon24Gallery, Icon24User } from "@vkontakte/icons";

const IDImageTypes = [
    {
        name: "Посетитель",
        desc: "Отобразит аватар посетителя",
        value: "current-user",
    },
    {
        name: "Пользователь",
        desc: "Отобразит аватар указанного пользователя",
        value: "user",
    },
    {
        name: "Сообщество",
        desc: "Отобразит аватар указанного сообщества",
        value: "public",
    },
    {
        name: "Вторая половинка",
        desc: "Отобразит аватар второй половинки посетителя, если она указана в профиле",
        value: "current-user-partner",
    },
    {
        name: "Случайный друг",
        desc: "Отобразит аватар случайного друга посетителя",
        value: "current-user-friend",
    },
];

const ImgSelect = (props) => {
    const { popout } = useContext(Context);

    const api = ApiVk.getInstance(null);

    const [select, setSelect] = useState(props.type_img == "id" ? 3 : 1);
    const [inputState, setInputState] = useState(true);
    const [imgMass, setImgMass] = useState([]);
    const [bottom_text, setBottomText] = useState("");
    const [isSpiner, setSpiner] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [hasNoDocumentImages, sethasNoDocumentImages] = useState(false);
    const [type_api] = useState(props.type_api ? props.type_api : "carousel");
    const [IDImageType, setIDImageType] = useState(
        props.iconId ? getIconIdType(props.iconId) : "current-user"
    );

    const type_img = props.type_img;

    const getImageType = () => {
        return Config.getTypeApiDescription(type_api).imgType;
    };

    const itemStyle: any = {
        flexShrink: 0,
        marginRight: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        fontSize: 12,
        paddingLeft: 2,
    };

    const style = {
        selected: {
            marginBottom: 8,
            backgroundColor: "#528BCC",
        },
        usSelected: {
            marginBottom: 8,
            backgroundColor: "#999999",
        },
    };

    /**
     * Отправка изображения на сервер для загрузки через VK API
     * @param {Object} e
     */
    const getImg = async (e) => {
        if (!e.target.files[0]) {
            return false;
        }

        if (e.target.files[0].size && e.target.files[0].size > 0) {
            const size = e.target.files[0].size / 1024 / 1024;
            if (size > MAX_FILE_UPLOAD_SIZE) {
                setSpiner(false);
                setHasError(true);
                return false;
            }
        }

        setSpiner(true);

        const response = await WidgetService.uploadImage({
            image: e.target.files[0],
            image_type: getImageType().real,
        });

        if (response.result === "error") {
            setSpiner(false);
            setHasError(true);

            let errorText;

            if (
                response.errors &&
                response.errors.image &&
                response.errors.image.length > 0
            ) {
                errorText = response.errors.image[0];
                if (
                    response.errors.image[0] === "The image failed to upload."
                ) {
                    errorText =
                        "Вы пытаетесь загрузить слишком большое или некорректное изображение";
                }
            } else if (response.message) {
                errorText = response.message;
            }

            popout.error(errorText, "Ошибка при загрузке изображения");
            props.close({ id: null, args: {} });

            return false;
        }

        props.callback({
            type: "img",
            payload: {
                cardID: props.cardID,
                img: response.response.images[0].url,
                img_vk: response.response.id,
            },
        });

        setSpiner(false);
        setHasError(false);

        props.close({ id: null, args: {} });
    };

    const getImgSource = async (e) => {
        setBottomText("");
        setInputState(true);

        e.preventDefault();

        const type = IDImageType;

        if (
            [
                "current-user",
                "current-user-partner",
                "current-user-friend",
            ].indexOf(type) >= 0
        ) {
            props.callback({
                type: "img",
                payload: {
                    cardID: props.cardID,
                    img: "person",
                    img_vk: type,
                },
            });

            props.close({ id: null, args: {} });
            return false;
        }

        let id = e.currentTarget.id.value;

        if (id.length > 0) {
            setSpiner(true);

            let result = null;

            switch (type) {
                case "user":
                    result = await api.getUserImg(id);
                    break;
                case "public":
                    result = await api.getCommunityById(id);
                    break;
                default:
                    setBottomText("Пожалуйста, выберите источник изображения");
                    setInputState(false);
                    return false;
            }

            if (result && result.response) {
                if (result.response.length > 0) {
                    setSpiner(false);

                    const item = result.response[0];

                    if (!item.photo_200) {
                        setBottomText("Изображения не найдены");
                        setInputState(false);
                        return false;
                    } else {
                        let result_id =
                            type === "public" ? `id-${id}` : `id${id}`;

                        props.callback({
                            type: "img",
                            payload: {
                                cardID: props.cardID,
                                img: item.photo_200,
                                img_vk: result_id,
                            },
                        });

                        props.close({ id: null, args: {} });
                    }
                } else {
                    setBottomText("По вашему запросу ничего не найдено");
                    setInputState(false);
                    return false;
                }
            } else if (result && result.message) {
                setBottomText(
                    `Ошибка - ${result.message ? result.message : ""}`
                );
                setInputState(false);
                return false;
            } else {
                setBottomText(UNKNOWN_ERROR);
                setInputState(false);
                return false;
            }
        } else {
            setInputState(false);
        }
        e.preventDefault();
    };

    const getImgDocument = async () => {
        setSpiner(true);
        try {
            const data = await api.getImgDocument(props.params.vk_user_id);

            if ("response" in data) {
                let arr = data.response.items.filter((items) => {
                    return items.preview !== undefined;
                });

                setImgMass(arr);

                if (arr.length === 0) {
                    sethasNoDocumentImages(true);
                    setBottomText("Изображения не найдены");
                }

                setSpiner(false);
            } else {
                console.log(data.message);
                setSpiner(false);
            }
        } catch (e) {
            console.log(e);
            setSpiner(false);
        }
    };

    /**
     * Загрузка изображения из документа
     * @param {object} item
     */

    const setImgDocument = async (item) => {
        setSpiner(true);

        const response = await WidgetService.uploadDocumentAsImage({
            url: item.url,
            ext: item.ext,
            image_type: getImageType().real,
        });

        if (response.result === "error") {
            setSpiner(false);
            setHasError(true);

            let errorMessage = "";

            if (response.errors && response.errors[0]) {
                errorMessage = response.errors[0];
            } else if (response.errors && response.errors["image"]) {
                errorMessage = response.errors["image"];
            } else if (response.message) {
                errorMessage = response.message;
            }

            popout.error(errorMessage, "Ошибка");
            props.close({ id: null, args: {} });
            return false;
        }

        props.callback({
            type: "img",
            payload: {
                cardID: props.cardID,
                img: response.response.images[0].url,
                img_vk: response.response.id,
            },
        });

        setSpiner(false);
        setHasError(false);

        props.close({ id: null, args: {} });
    };

    const renderImgDocumentSelect = () => {
        if (isSpiner) {
            return (
                <div className="img-select-spinner-wrapper">
                    <Spinner size="medium" />
                </div>
            );
        }

        return (
            <div>
                {imgMass.length == 0 ? (
                    <>
                        {hasNoDocumentImages ? (
                            <div
                                style={{
                                    textAlign: "center",
                                    marginBottom: 10,
                                    color: "#e64646",
                                    fontSize: 14,
                                }}
                            >
                                {bottom_text}
                            </div>
                        ) : (
                            ""
                        )}
                        <Button size="l" onClick={getImgDocument} stretched>
                            Выбрать документ
                        </Button>
                    </>
                ) : null}
                <div style={{ color: "#76787A", margin: "5px 0 5px" }}>
                    {imgMass.map((item) => (
                        <img
                            onClick={() => setImgDocument(item)}
                            key={item.id}
                            style={{ width: "50px", margin: "1px" }}
                            src={item.preview.photo.sizes[0].src}
                            alt=""
                        />
                    ))}
                </div>
                {imgMass.length == 0 ? (
                    <div
                        style={{
                            color: hasError ? "#e64646" : "#76787A",
                            margin: "10px 0 20px",
                        }}
                    >
                        Рекомендуемое изображение {getImageType().upload}. JPG
                        или PNG. Макс. размер 7мб
                    </div>
                ) : null}
            </div>
        );
    };

    let canUseIds = ["tiles", "list", "compact_list"].indexOf(props.type) >= 0;

    return (
        <div className="ImgSelect">
            <div style={{ display: "flex" }}>
                <div
                    style={itemStyle}
                    onClick={() => {
                        setSelect(1);
                        setHasError(false);
                    }}
                >
                    <Avatar
                        size={64}
                        style={select == 1 ? style.selected : style.usSelected}
                    >
                        <Icon24Gallery style={{ color: "white" }} />
                    </Avatar>
                    Фото
                </div>

                <div
                    style={itemStyle}
                    onClick={() => {
                        setSelect(2);
                        setHasError(false);
                    }}
                >
                    <Avatar
                        size={64}
                        style={select == 2 ? style.selected : style.usSelected}
                    >
                        <Icon24Document style={{ color: "white" }} />
                    </Avatar>
                    Документы
                </div>

                {canUseIds && (
                    <div
                        style={itemStyle}
                        onClick={() => {
                            setSelect(3);
                            setHasError(false);
                        }}
                    >
                        <Avatar
                            size={64}
                            style={
                                select == 3 ? style.selected : style.usSelected
                            }
                        >
                            <Icon24User style={{ color: "white" }} />
                        </Avatar>
                        По ID
                    </div>
                )}
            </div>
            {canUseIds && type_img === "mixed" && (
                <div
                    className="has-error"
                    style={{
                        fontSize: 14,
                        color: "var(--vkui--color_accent_red)",
                        paddingTop: 12,
                    }}
                >
                    Изображения для всех элементов должны быть заданы либо
                    только через Фото\Документы, либо только через ID
                </div>
            )}
            <Separator style={{ margin: "10px 0", opacity: ".4" }} />
            <div>
                {select == 1 && (
                    <div>
                        {isSpiner ? (
                            <Spinner size="medium" />
                        ) : (
                            <File
                                stretched={true}
                                onChange={getImg}
                                align="center"
                                size="l"
                            >
                                Загрузить изображение
                            </File>
                        )}

                        <div
                            style={{
                                color: hasError ? "#e64646" : "#76787A",
                                margin: "10px 0 20px",
                            }}
                        >
                            Рекомендуемое изображение {getImageType().upload}.
                            JPG или PNG. Макс. размер 7мб
                        </div>
                    </div>
                )}
                {select == 2 && renderImgDocumentSelect()}
                {select == 3 && (
                    <FormLayout onSubmit={getImgSource}>
                        <div style={{ marginBottom: 12 }}>
                            {IDImageTypes.map((type) => {
                                return (
                                    <Radio
                                        className="ImgSelect__radio"
                                        key={type.value}
                                        onChange={() => {
                                            setIDImageType(type.value);
                                        }}
                                        name="radio"
                                        value={type.value}
                                        checked={IDImageType === type.value}
                                        description={type.desc}
                                    >
                                        {type.name}
                                    </Radio>
                                );
                            })}
                        </div>

                        {["user", "public"].indexOf(IDImageType) >= 0 ? (
                            <FormItem
                                status={inputState ? null : "error"}
                                bottom={bottom_text ? bottom_text : ""}
                                style={{
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    paddingTop: 0,
                                }}
                            >
                                <Input
                                    style={{ display: "flex" }}
                                    name="id"
                                    type="number"
                                    defaultValue={parseIconId(props.iconId).id}
                                    placeholder="Введите id"
                                    onChange={(e) => {
                                        setInputState(true);
                                        setBottomText("");
                                    }}
                                />
                            </FormItem>
                        ) : null}

                        {isSpiner ? (
                            <Spinner size="medium" />
                        ) : (
                            <Div
                                style={{
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                }}
                            >
                                <Button type="submit" size="l" stretched>
                                    Добавить
                                </Button>
                            </Div>
                        )}
                    </FormLayout>
                )}
                {platform() === Platform.ANDROID ? (
                    <Button
                        onClick={() => {
                            props.close({ id: null, args: {} });
                        }}
                        mode="tertiary"
                        style={{ margin: 0, width: "100%" }}
                        stretched
                        size="l"
                    >
                        Отмена
                    </Button>
                ) : null}
            </div>
        </div>
    );
};

export default ImgSelect;
