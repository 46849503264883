import { WIDGET_MAX_AUDIENCE_COMMUNITIES } from "./Constants";

export const TOKEN_REQUEST_FAILED =
    "Ошибка при получении токена сообщества. Пожалуйста, дайте приложению доступ к данным сообщества или обратитесь в техническую поддержку";
export const INVALID_IMAGES_ERROR =
    "Возможно, вы переустанавливали приложение. В таком случае необходимо заново загрузить изображения для виджета. Если это не поможет, то обратитесь в техническую поддержку.";
export const USER_TOKEN_REQUEST_FAILED =
    "Ошибка при получении токена авторизации пользователя. Пожалуйста, обратитесь в техническую поддержку.";

export const COMMUNITY_TOKEN_REQUEST_FAILED =
    "Ошибка при получении токена сообщества. Пожалуйста, обратитесь в техническую поддержку.";

export const UNKNOWN_ERROR =
    "Неизвестная ошибка. Пожалуйста, обратитесь в техническую поддержку.";

export const TILES_IMAGES_ARE_NOT_THE_SAME_TYPE =
    "Изображения для всех элементов должны быть заданы либо только через Фото\\Документы, либо только через ID";

export const WIDGET_ACCESS_ERROR_MESSAGE =
    "Необходимо переустановить приложение";

export const WIDGET_PUBLISH_FLUD_CONTROL =
    "Изменять виджеты можно не чаще, чем 1 раз в 10 секунд";

export const UNSUPORTED_PUBLISH_PLATFORM_ERROR_MESSAGE =
    "Публикация виджетов возможна только из полной версии или из мобильного приложения ВКонтакте";

export const IMAGE_UPLOAD_ERROR = "При загрузке изображения произошла ошибка";

export const IMAGE_SIZE_ERROR = "Размер изображения больше 7мб";

export const WIDGET_MAX_AUDIENCE_COMMUNITIES_ERROR = `Достигнут лимит выбранных сообществ в настройках аудитории в фильтрах "Состоит в сообществе" и "Не состоит в сообществе". Максимум ${WIDGET_MAX_AUDIENCE_COMMUNITIES} сообществ на все виджеты данного типа`;
