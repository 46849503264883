import React, { useState } from "react";
import {
    Button,
    FormLayout,
    Input,
    Cell,
    Switch,
    Separator,
    Textarea,
    Avatar,
    File,
    Spinner,
    Div,
    FormItem,
} from "@vkontakte/vkui";
import ApiVk from "../../../../../../includes/ApiVk";
import FileService from "../../../../../../includes/Services/FileService";
import container from "../../../../../../container";
import {
    IMAGE_SIZE_ERROR,
    IMAGE_UPLOAD_ERROR,
} from "../../../../../../includes/ErrorMessages";
import { Icon28Camera } from "@vkontakte/icons";
import * as Sentry from "@sentry/react";

type ReviewModalProps = {
    name: string;
    text: string;
    vkUrl: string;
    imgUrl: string;
    isEdit: boolean;
    callback: Function;
    close: Function;
};

const logger = container.get("logger");

/**
 * Убрать логику в редюсер
 */

const ReviewModal = (props: ReviewModalProps) => {
    const api = ApiVk.getInstance(null);

    const {
        name = "",
        text = "",
        vkUrl = "",
        imgUrl = "",
        isEdit = false,
        callback = () => {},
        close = () => {},
    } = props;

    const [url, setUrl] = useState(vkUrl ? vkUrl : "");
    const [urlHasError, setUrlHasError] = useState(false);
    const [urlHelpText, setUrlHelpText] = useState("");

    const [reviewName, setReviewName] = useState(name ? name : "");
    const [reviewNameHasError, setReviewNameHasError] = useState(false);

    const [reviewText, setReviewText] = useState(text ? text : "");
    const [reviewTextHasError, setReviewTextHasError] = useState(false);

    const [img, setImg] = useState(imgUrl ? imgUrl : "");
    const [imgError, setImgError] = useState("");

    const [inputSwitchLink, setInputSwitchLink] = useState(!!vkUrl);

    const [isPending, setIsPending] = useState(false);

    const saveModal = () => {
        const data: any = {};

        const errors = [];

        if (url && inputSwitchLink) {
            data.vkUrl = url;
        }

        if (reviewName.trim() === "") {
            errors.push(1);
            setReviewNameHasError(true);
        }

        if (reviewText.trim() === "") {
            errors.push(1);
            setReviewTextHasError(true);
        }

        if (errors.length > 0) return false;

        data.name = reviewName;
        data.text = reviewText;

        if (img) {
            data.img = {
                url: img,
            };
        }

        callback(data);
        close();
    };

    const getImg = async (e) => {
        try {
            if (!e.target.files[0]) {
                return false;
            }

            setIsPending(true);

            if (e.target.files[0].size > 7000000) {
                setIsPending(false);
                setImgError(IMAGE_SIZE_ERROR);
                return false;
            }

            let response = await FileService.uploadImage({
                image: e.target.files[0],
                size: "100x100",
            });

            if (response.result === "error") {
                setIsPending(false);
                setImgError(IMAGE_UPLOAD_ERROR);
                logger.error(
                    {
                        code: 9103,
                        message: response.message,
                    },
                    "images_error",
                    "ReviewModal.tsx"
                );
                console.log(response.message);

                return false;
            }

            setIsPending(false);
            setImg(response.data.filename); // Возьмем последнее в списке изображение - с наибольшим разрешением
            setImgError("");
        } catch (error) {
            logger.error(
                {
                    code: 9104,
                    message: error.message,
                },
                "images_error",
                "ReviewModal.tsx"
            );
            console.log(error);
            Sentry.captureException(error);

            setImgError(IMAGE_UPLOAD_ERROR);
            setIsPending(false);
        }
    };

    const getFeedBack = async () => {
        if (url.indexOf("vk.com") < 0) {
            setUrlHasError(true);
            setUrlHelpText("Введите внутреннюю ссылку vk.com");
            return false;
        }

        setIsPending(true);

        const response = await api.getComment(url);

        setIsPending(false);

        if (response.result === "success") {
            callback({
                name: response.name,
                text: response.text,
                img: { url: response.img.url },
                vkUrl: response.vkUrl,
            });

            close();
        } else {
            setUrlHasError(true);
            setUrlHelpText(response.message);
        }
    };

    const imgLoad = () => {
        if (!img) {
            return (
                <div style={{ marginRight: 6 }}>
                    <Avatar style={{ paddingLeft: 0 }}>
                        <Icon28Camera />
                    </Avatar>
                </div>
            );
        }
        return (
            <div style={{ marginRight: 6 }}>
                <Avatar src={img} style={{ paddingLeft: 0 }} />
            </div>
        );
    };

    return (
        <div className="B-Reviews_edit">
            <Cell
                hasActive={false}
                hasHover={false}
                after={
                    <Switch
                        disabled={false}
                        checked={inputSwitchLink}
                        onChange={() => {
                            setInputSwitchLink(inputSwitchLink ? false : true);
                        }}
                    />
                }
            >
                Ссылка на отзыв
            </Cell>
            <Separator style={{ opacity: ".12" }} />
            {inputSwitchLink && (
                <FormLayout>
                    <FormItem
                        status={urlHasError ? "error" : null}
                        bottom={
                            urlHasError
                                ? urlHelpText
                                : "*cсылка должна быть на отзыв из обсуждения или комментарий к посту."
                        }
                        style={{ paddingTop: 6 }}
                    >
                        <Input
                            name="link"
                            type="text"
                            value={url}
                            onChange={(e) => {
                                setUrl(e.target.value);
                                setUrlHasError(false);
                            }}
                            placeholder="Ссылка vk.com"
                        />
                    </FormItem>
                    <Div>
                        <Button
                            disabled={isPending}
                            size="l"
                            stretched
                            onClick={getFeedBack}
                        >
                            {isPending ? (
                                <Spinner size="regular" />
                            ) : (
                                "Загрузить"
                            )}
                        </Button>
                    </Div>
                </FormLayout>
            )}

            {!inputSwitchLink && (
                <FormLayout>
                    <FormItem
                        top="Имя"
                        status={reviewNameHasError ? "error" : "default"}
                        style={{ paddingTop: 6 }}
                    >
                        <Input
                            name="title"
                            type="text"
                            value={reviewName}
                            onChange={(e) => {
                                let value = e.target.value;
                                setReviewName(value);
                                setReviewNameHasError(false);
                            }}
                            placeholder="Кто оставил отзыв?"
                        />
                    </FormItem>
                    <FormItem
                        top="Текст"
                        status={reviewTextHasError ? "error" : null}
                    >
                        <Textarea
                            name="text"
                            rows={150}
                            value={reviewText}
                            onChange={(e) => {
                                let value = e.target.value;
                                setReviewText(value);
                                setReviewTextHasError(false);
                            }}
                            placeholder="Текст отзыва"
                        />
                    </FormItem>

                    <FormItem
                        style={{
                            paddingTop: 6,
                            paddingBottom: 6,
                        }}
                        status={imgError ? "error" : "default"}
                        bottom={imgError ? imgError : ""}
                    >
                        {isPending ? (
                            <Spinner size="medium" />
                        ) : (
                            <File
                                style={{ padding: "0px" }}
                                onChange={getImg}
                                before={imgLoad()}
                                mode="tertiary"
                            >
                                {isEdit ? "Изменить" : "Добавить"} фото
                            </File>
                        )}
                    </FormItem>

                    <Div>
                        <Button size="l" stretched onClick={saveModal}>
                            {isEdit ? "Сохранить" : "Добавить"}
                        </Button>
                    </Div>
                </FormLayout>
            )}
        </div>
    );
};

export default ReviewModal;
